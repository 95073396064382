<template>
  <v-container fluid >
    <v-row justify="center" class="pt-16 pb-10 fill-height" >
      <v-col>
        <h1 class="text-center toolbar--text">{{ $t("404") }}</h1>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {}
</script>
